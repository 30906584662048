<template>
    <div class="result">
        <div class="result-content">
            <span class="alipay">
                <i class="iconfont icon-zhifubao"></i>
            </span>



        </div>
        <div class="back-btn">
            <a href="javascript: void (0)" @click="getOrderStatus">我已支付</a>
            <a class="not-pay" href="javascript: void (0)" @click="$router.back()">暂未支付</a>
        </div>
    </div>
</template>

<script>
    import {user} from '@/api'
    export default {
        name: "index",
        data() {
            return {
                price: '',
                order: ''
            }
        },
        created() {

            if (!this.route.query.total_amount) return this.fail = true;
            this.price = this.$route.query.total_amount;
            this.order = this.$route.query.out_trade_no;

        },

        methods: {
            async getOrderStatus(){
                const {code, data, msg} = await user.aliPayQuery({tradeRecordId: this.$route.query.out_trade_no});

                if(data){
                    this.$toast.success('支付成功');
                    setTimeout(_ => {
                        this.$router.push('/record?tab=RECHARGE');
                    },1500);
                }else {
                    this.$toast.error('支付失败');

                    setTimeout(_ => {
                        this.$router.go(-2);
                    },1500);
                }

            },
            success(){
                this.$router.go(-1);
            },

        }
    }
</script>

<style scoped lang="less">
    .result {
        height: 100vh;
        display: flex;
        flex-direction: column;

        .result-content {
            flex: 1;
            box-sizing: border-box;
            padding: 100px 50px;
            display: flex;
            flex-direction: column;
            align-items: center;

            .alipay {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 45px;
                height: 45px;
                background: var(--zhifubao);
                border-radius: 100%;

                i {
                    font-size: 40px;
                    margin-left: 8px;
                    color: #fff;
                }

            }

            .wechat {
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 100%;

                i {
                    font-size: 65px;
                    color: var(--weixin);
                }
            }

            .result-text {
                display: block;
                margin-top: 4px;
                font-size: 16px;
            }

            .result-price {
                font-size: 24px;
                font-weight: bold;
                margin-top: 20px;
            }
        }

      .back-btn {
        height: 165px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        a {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 45px;
          width: 270px;
          border-radius: 22px;
          background: var(--text-color);
          color: #fff;
          font-size: 16px;
          margin-bottom: 12px;
        }

        .not-pay{
          background: #eeeeee;
          color: #151d36;
        }
      }
    }
</style>
